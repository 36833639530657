/* Import Google Font */
/* Font import */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

/* Global styles */
body {
    font-family: 'Roboto', sans-serif;
    background-color: #f5f5f5; 
    display:flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    margin: 0;
    background-color: #f5f5f5;

    /* Example background color */
}

.container {
    text-align: center;
    padding: 40px 20px;
}

.header {
    margin-bottom: 20px;
}

.city-input,
.search-button,
.location-button {
    padding: 10px;
    font-size: 16px;
    margin: 10px;
    border: none;
    outline: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.search-button:hover,
.location-button:hover {
    background-color: #007bff; /* Change to your desired hover color */
    color: white; /* Adjust text color for better contrast */
}

.search-button:disabled,
.location-button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.error-message {
    color: red;
}

.main-content {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    margin: 20px auto;
    max-width: 1200px;
}
.weather-info:hover{
    background-color: #71bee5; /* Change to your desired hover color */
    color: white;  
}

.weather-info,
.suggestions {
    flex: 1;
    background-color: #f0f0f0;
    border-radius: 10px;
    padding: 0px;
    margin:5px;
}

.weather-info {
    max-width:25%;
    cursor: pointer;
}

.suggestions {
    max-width:15%;
    animation: fadeIn 1s ease-in-out;
}

.current-weather {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.weather-detail,
.air-conditions {
    margin: 10px;
}
/* .hourly-forecast{
    background-color: #e0e0e0;
    border-radius: 10px;
    padding: 20px;
    margin: 20px 0;
    border: 3px solid black
}
.hourly-forecast-item{
    border-radius: 10px;
    padding: 20px;
    margin: 20px 0;
    border: 3px solid black
}
.hourly-forecast-items{
    display:flex;
    flex-wrap: wrap;
} */
.forecast {
    background-color: #e0e0e0;
    border-radius: 10px;
    padding: 20px;
    margin: 20px 0;
}

.forecast-days {
    display: flex;
    justify-content: space-around;
}

.forecast-day {
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
    margin: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}
.forecast-day:hover{
    background-color: #71bee5; /* Change to your desired hover color */
    color: white;  
}

.forecast-day h4 {
    margin: 10px 0;
}

.forecast-day p {
    margin: 5px 0;
}

.suggestion {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    margin: 10px 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    animation: fadeIn 1s ease-in-out;
    cursor: pointer;
}

.suggestion h4 {
    margin-bottom: 10px;
    font-weight: 700;
}
.suggestion:hover{
    background-color: #71bee5; /* Change to your desired hover color */
    color: white;  
}

.suggestion p {
    margin: 0;
    font-weight: 400;
}
/* .hourly-forecast {
    
    background-color: #e0e0e0;
    border-radius:5px;
    padding:15px;
    margin: 5px 0;
    border: 3px solid black;
    max-width: 1000px;
    width:100%
    
} */

.hourly-forecast {
    background-color: #e0e0e0;
    border-radius: 5px;
    padding: 15px;
    margin: 5px auto; /* Updated margin to auto for centering horizontally */
    border: 3px solid black;
    max-width: 1000px;
    width: 100%;
    display: flex; /* Ensures items inside are aligned properly */
    flex-direction: column; /* Arrange items in a column layout */
    align-items: center; /* Center items horizontally */
}

.hourly-forecast h3 {
    text-align: center; /* Center align the text */
    margin-top: 0; /* Remove default margin */
}


.hourly-forecast-items {
    display: flex;
    flex-wrap: wrap;
    gap:5px; /* Add some space between items */
}

.hourly-forecast-item {
    border-radius:5px;
    padding:5px;
    margin:5px;
    border: 2px solid black;
    flex: 1 1 calc(1% - 5px); /* Adjusts the item size to be 25% of the container with margin taken into account */
    box-sizing: border-box; /* Ensures padding and border are included in the width/height calculation */
    text-align: center;
}


.hourly-forecast-item h4 {
    margin: 5px 0;
    font-size: 16px;
}

.hourly-forecast-item p {
    margin: 5px 0;
    font-size: 14px;
}

.hourly-forecast-item img {
    width: 40px;
    height: 40px;
    margin-top: 5px;
}

/* .area-chart-container {
    margin: 20px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
} */

/* .area-chart-container h3 {
    color: #f5f6fd;
    font-size: 1.5rem;
    margin-bottom: 10px;
} */

.area-chart-container {
    margin: 20px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.area-chart-container h3 {
    text-align: center;
    margin-top: 0;
}

.suggestion-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
}

.suggestion {
    padding: 10px 20px;
    margin: 5px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.suggestion:hover {
    background-color: #f0f0f0;
}

.suggestion.active {
    background-color: #d1e7dd;
    border-color: #badbcc;
}/* Additional CSS styles as needed */

.bar-chart-container {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
}

.bar-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between; /* Adjust as per your spacing needs */
}

.charts-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.bar-chart-container {
    margin-bottom: 20px; /* Adjust margin between charts */
}


/* Fade-in Animation */
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
